import {GradientButton, Section, TitleBox, Wrapper} from "../../components/Common";
import styled from "styled-components";

const PageWrapper = styled.div`{
  position: relative;
  width: 100vw;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}`;

const VersionWrapper = styled.div<{ isMobile: boolean }>`{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${p => p.isMobile ? `
  margin-bottom: 30px;
  &:last-child {
    margin-bottom: 0px;
  }
  ` : `
  margin: 0 20px;
  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
  `}
  > .title {
    margin-top: 30px;
    margin-bottom: 20px;
    font-family: Poppins;
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: -0.005em;
    text-align: center;
    color: #062460;
  }
}`
const RoadmapCard = ({
                         index,
                         isDone = false,
                         isMobile,
                         children
                     }: { index: number, isDone?: boolean, isMobile: boolean, children: any }) => {
    const position = (index % 2 == 0) ? 'left' : 'right'
    return <Card className='wow fadeInUp' isDone={isDone} isMobile={isMobile}>
        {isDone && <img className={'done ' + position} src={`/img/home/roadmap/done_${position}.png`}/>}
        {children}
    </Card>
}
const Card = styled.div<{ isDone: boolean, isMobile: boolean }>`{
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;

  font-family: Pretendard;

  font-weight: 500;
  line-height: 26px;
  letter-spacing: -0.005em;
  text-align: center;
  border-radius: 12px;
  margin-bottom: 8px;
  position: relative;
  
  ${p => p.isMobile ? `
  width: 272px;
  padding: 16px 25px;
  font-size: 14px;
  ` : `
  width: 300px;
  padding: 16px 25px;
  font-size: 16px;
  `}

  ${p => p.isDone ? `
    color: #FFFFFF;
    background: #062460;
  ` : `
    color: #062460;
    background: #FFFFFF;
  `}
  &:last-child {
    margin-bottom: 0;
  }

  > .done {
    position: absolute;
  }

  > .left {
    top: -15px;
    left: -20px;
  }

  > .right {
    top: -15px;
    right: -30px;
  }
}`

const VersionCircle = styled.div<{ backgroundImage: string }>`{
  width: 80px;
  height: 80px;
  background-image: url("${p => p.backgroundImage}");
  background-size: contain;
  box-sizing: border-box;
  padding: 7px;

  > .version {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.5);
    box-shadow: 0px 8px 52px -10px rgba(0, 0, 0, 0.14);
    backdrop-filter: blur(80px);
    border-radius: 50%;
    font-family: Poppins;
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: -0.005em;
    text-align: center;
    color: #062460;

  }
}`

const ContentWrapper = styled.div<{ isMobile: boolean }>`{
  margin-top: 60px;
  width: fit-content;
  display: flex;


  justify-content: center;
  position: relative;
  ${p => p.isMobile ? `
  flex-direction: column;
  align-items: center;
  ` : `
  flex-direction: row;
  align-items: flex-start;
  `}
}`;

const DotBar = styled.div`{
  top: 40px;
  position: absolute;
  width: 100vw;
  height: 1px;
  border-bottom: 2px dashed #062460;
}`;


const Roadmap = (props: any) => {
    const isMobileVal = props.isMobileVal;
    return (
        <Section ref={props.innerRef} isFull={false} paddingTop={120} paddingBottom={isMobileVal ? 120 : 140}
                 backgroundColor={'#F9CFCF'}>
            <PageWrapper>
                <TitleBox className='wow fadeInUp' margin={18} isMobile={isMobileVal}>
                    <div className='title'>
                        Roadmap
                    </div>
                    <div className='title-background'>
                        Roadmap
                    </div>
                </TitleBox>
                <ContentWrapper isMobile={isMobileVal}>
                    {!isMobileVal && <DotBar/>}
                    <VersionWrapper isMobile={isMobileVal}>
                        <VersionCircle className='wow fadeInUp' backgroundImage={'/img/home/roadmap/version_bg.png'}>
                            <span className='version'>1.0</span>
                        </VersionCircle>
                        <div className='title wow fadeInUp'>
                            NFT
                        </div>
                        <RoadmapCard isMobile={isMobileVal} index={0} isDone={true}>Kita’s Friends NFT Whitepaper
                            발행</RoadmapCard>
                        <RoadmapCard isMobile={isMobileVal} index={1} isDone={true}>Kita’s Friends 제네시스 NFT
                            민팅</RoadmapCard>
                        <RoadmapCard isMobile={isMobileVal} index={2} isDone={true}>Kita’s Friends 랜덤박스 진행<br/>(촬영 소품 및
                            굿즈 배송)</RoadmapCard>
                    </VersionWrapper>

                    <VersionWrapper isMobile={isMobileVal}>
                        <VersionCircle className='wow fadeInUp' backgroundImage={'/img/home/roadmap/version_bg.png'}>
                            <span className='version'>2.0</span>
                        </VersionCircle>
                        <div className='title wow fadeInUp'>
                            Governance
                        </div>
                        <RoadmapCard isMobile={isMobileVal} index={0}>OA Planet 웹페이지 추가</RoadmapCard>
                        <RoadmapCard isMobile={isMobileVal} index={1}>Kita’s Friends NFT 스테이킹 출시</RoadmapCard>
                        <RoadmapCard isMobile={isMobileVal} index={2}>Governance Pool 런칭: gOAP 발행</RoadmapCard>
                        <RoadmapCard isMobile={isMobileVal} index={3}>Governance DAO 런칭</RoadmapCard>
                    </VersionWrapper>

                    <VersionWrapper isMobile={isMobileVal}>
                        <VersionCircle className='wow fadeInUp' backgroundImage={'/img/home/roadmap/version_bg.png'}>
                            <span className='version'>3.0</span>
                        </VersionCircle>
                        <div className='title wow fadeInUp'>
                            Brand & Token
                        </div>
                        <RoadmapCard isMobile={isMobileVal} index={0}>DAO를 통한 브랜드 및 상품 지정</RoadmapCard>
                        <RoadmapCard isMobile={isMobileVal} index={1}>브랜드 및 상품 런칭</RoadmapCard>
                        <RoadmapCard isMobile={isMobileVal} index={2}>판매 링크 제공 및 판매 시작</RoadmapCard>
                        <RoadmapCard isMobile={isMobileVal} index={3}>Utility Token: OAP 발행</RoadmapCard>
                    </VersionWrapper>
                </ContentWrapper>

            </PageWrapper>
        </Section>
    )
};

export default Roadmap;