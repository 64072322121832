import styled from "styled-components";
import {useMediaQuery} from "react-responsive";
import {MOBILE_QUERY} from "../utils/Utils";

const FooterWrapper = styled.div<{isMobile: boolean}>`
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  
  
  background: #000000;
  ${p => p.isMobile ? `
    height: fit-content;
    padding: 42px 0 52px;
  ` : `
    height: 140px;
  `}
  
  > .content {
    width: 100%;
    max-width: 1080px;
    display: flex;
    ${p => p.isMobile? `
    flex-direction: column;
    `:`
    flex-direction: row;
    `}
    align-items: center;
    justify-content: space-between;
  }
`;

const Contact = styled.div<{ isMobile: boolean }>`

  ${p => p.isMobile ? `
    margin-bottom: 30px;
  ` : `
    margin-left: 60px;
    margin-bottom: 6px;
  `}
  > .copyright {
    font-family: Pretendard;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: -0.005em;

    color: #FFFFFF;

    ${p => p.isMobile ? `
    
    ` : `
        text-align: left;
    `}
  }

  > .contact {
    font-family: Pretendard;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: -0.005em;
    color: #FFFFFF;
    ${p => p.isMobile ? `
        margin-top: 4px;
    ` : `
        text-align: left;
    `}
  }
`;
const SNSWrapper = styled.div`{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  > .button {
    cursor: pointer;
    width: 48px;
    height: 48px;
    border: 1px solid #FFFFFF;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px 5px;
    box-shadow: 0 0 1px 0px white inset, 0 0 1px 0px white, 0 0 1px 0px white, 0 0 1px 0px white, 0 0 1px 0px white, 0 0 1px 0px white, 0 0 1px 0px white;
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
}`;

const Footer = () => {
    const isMobileVal = useMediaQuery({
        query: MOBILE_QUERY
    });

    return (
            <FooterWrapper isMobile={isMobileVal}>
                <div className='content'>
                    <Contact isMobile={isMobileVal}>
                        <div className='copyright'>
                            COPYRIGHT © (Company name) all rights reserved.
                        </div>
                        <div className='contact'>
                            Contact@kita.io
                        </div>
                    </Contact>
                    <SNSWrapper>
                        <div className='button'>
                            <img src='/img/common/ic_twitter.svg'/>
                        </div>
                        <div className='button'>
                            <img src='/img/common/ic_discord.svg'/>
                        </div>
                        <div className='button'>
                            <img src='/img/common/ic_medium.svg'/>
                        </div>
                        <div className='button'>
                            <img src='/img/common/ic_telegram.svg'/>
                        </div>
                        <div className='button'>
                            <img src='/img/common/ic_opensea.svg'/>
                        </div>
                    </SNSWrapper>
                </div>
            </FooterWrapper>
    )
};

export default Footer;
