import {useMediaQuery} from "react-responsive";
import {MOBILE_QUERY} from "../utils/Utils";
import {Wrapper} from "../components/Common";

const NotFound = () => {

    const isMobileVal = useMediaQuery({
        query: MOBILE_QUERY
    });

    window.scrollTo(0, 0);

    return (
        <Wrapper className="prevent-block">

        </Wrapper>
    )
};

export default NotFound;
