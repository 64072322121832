import {Section, TitleBox, Wrapper} from "../../components/Common";
import styled from "styled-components";
import CollectionSwiper from "../../components/Home/CollectionSwiper";
import Marquee from "../../components/Home/Marquee";
import {useMediaQuery} from "react-responsive";
import {MOBILE_QUERY} from "../../utils/Utils";

const PageWrapper = styled.div`{
  position: relative;
  width: 100vw;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}`;

const Collection = (props: any) => {
    const isMobileVal = props.isMobileVal;
    return (
        <Section ref={props.innerRef} isFull={false} paddingTop={isMobileVal ? 100 : 140}
                 paddingBottom={isMobileVal ? 197 : 140} backgroundColor={'#ffffff'}>
            <PageWrapper>
                <TitleBox className='wow fadeInUp' margin={18} isMobile={isMobileVal}>
                    <div className='title'>
                        NFT Collection
                    </div>
                    <div className='sub-title-s'>
                        총 6,000장의 NFT로 구성된 OA행성에 사는 키타의 친구들 컬렉션
                    </div>
                </TitleBox>
                <CollectionSwiper isMobile={isMobileVal}/>
            </PageWrapper>
        </Section>
    )
};

export default Collection;