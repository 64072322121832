import {Section, TitleBox} from "../../components/Common";
import styled from "styled-components";
import {Dispatch, SetStateAction, useState} from "react";
import Collapse from "@kunukn/react-collapse";

const PageWrapper = styled.div`{
  position: relative;
  width: 100vw;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}`;

const Background = styled.img<{isMobile: boolean}>`{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  ${p => p.isMobile ? `
  object-fit: cover;
  `:`
  object-fit: fill;
  `}
}`;

const FAQSection = styled(Section)`{
  width: 100vw;
  position: relative;
}`;

const FAQWrapper = styled.div<{isMobile: boolean}>`{
  display: flex;
  flex-direction: column;
  margin-top: 60px;
  
  ${p => p.isMobile ? `
  align-items: center;
  ` : ``}

  .collapse-css-transition {
    transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1);
    overflow: hidden;
  }
}`;

const FAQTitleBar = styled.div<{isMobile: boolean}>`{
  width: 100%;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;

  > .title {

    flex: 1;
    font-family: Pretendard;
    ${p => p.isMobile ? `
    font-size: 16px;
    line-height: 150%;
    word-break: keep-all;
    `: `
    font-size: 18px;
    line-height: 27px;
    
    `}
    font-weight: 500;
    
    letter-spacing: 0em;
    text-align: left;
    color: #062460;
  }

  > .ic-question {
    margin-right: 12px;
  }
}`;

const FAQCard = styled.div<{isMobile: boolean}>`{
  
  box-sizing: border-box;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 12px;
  margin-bottom: 12px;
  
  ${p => p.isMobile ? `
  width: calc(100% - 48px);
  padding: 24px 20px 24px 20px;
  ` : `
  width: 960px;
  padding: 24px 40px 24px 50px;
  `}
  .description {
    padding-right: 30px;
    margin-top: 20px;
    font-family: Pretendard;
    ${p => p.isMobile ? `
  font-size: 14px;
  line-height: 150%;
  word-break: keep-all;
  ` : `
  font-size: 16px;
  line-height: 24px;
  `}
    font-weight: 500;
    
    letter-spacing: 0em;
    text-align: left;
    color: #131624;

    > .bold {
      font-weight: 700;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
}`

export interface FAQItemProps {
    children: any;
    title: string;
    itemIndex: number;
    selectedIndex: number;
    setExpandIndex: Dispatch<SetStateAction<number>>;
    isMobile: boolean;
}

const FAQItem = ({children, title, itemIndex, selectedIndex, setExpandIndex, isMobile}: FAQItemProps) => {
    return (
        <FAQCard isMobile={isMobile} className='wow fadeInUp' data-wow-offset={itemIndex * 60} onClick={() => {
            (itemIndex === selectedIndex) ? setExpandIndex(-1) : setExpandIndex(itemIndex)
        }}>
            <FAQTitleBar isMobile={isMobile}>
                <img className='ic-question' src='/img/home/faq/ic_question.svg'/>
                <span className='title'>{title}</span>
                <img className='ic-question'
                     src={(itemIndex === selectedIndex) ? '/img/home/faq/ic_arrow_down.svg' : '/img/home/faq/ic_arrow_up.svg'}/>
            </FAQTitleBar>
            <Collapse isOpen={(itemIndex === selectedIndex)}>
                {children}
            </Collapse>
        </FAQCard>
    )
};
const FAQ = (props: any) => {
    const isMobileVal = props.isMobileVal;
    const [expandIndex, setExpandIndex] = useState(-1)

    return (
        <FAQSection ref={props.innerRef} isFull={false} paddingTop={120} paddingBottom={isMobileVal? 0 : 160}>
            <Background isMobile={isMobileVal} src={`/img/home/faq/bg${isMobileVal ? '_m' : ''}.svg`}/>
            <PageWrapper>
                <TitleBox className='wow fadeInUp' margin={18} isMobile={isMobileVal}>
                    <div className='title'>
                        FAQ
                    </div>
                    <div className='title-background'>
                        FAQ
                    </div>
                </TitleBox>
                <FAQWrapper isMobile={isMobileVal}>
                    <FAQItem
                        isMobile={isMobileVal}
                        itemIndex={0}
                        selectedIndex={expandIndex}
                        title={'어떤 블록체인 위에서 운영되나요?'}
                        setExpandIndex={setExpandIndex}
                    >
                        <div className='description'>
                            Kita’s Friends NFT는 현재 전세계적으로 가장 큰 거래 규모를 기록 중인 이더리움(ETH) 블록체인 위에서 운영됩니다.<br/>
                            아래 링크를 따라, 이더리움(ETH) 구매 및 사용, 메타마스크(Metamask) 설정 등을 참고하세요.<br/>
                            [이더리움 관련 미디엄 링크]
                        </div>
                    </FAQItem>
                    <FAQItem
                        isMobile={isMobileVal}
                        itemIndex={1}
                        selectedIndex={expandIndex}
                        title={'Kita’s Friends NFT는 어떤 프로젝트인가요?'}
                        setExpandIndex={setExpandIndex}
                    >
                        <div className='description'>
                            <span className='bold'>ART </span>: Kita’s Friends NFT의 Art는 단순한 PFP NFT를 넘어 세계관과 스토리가 있고,
                            다양한 패션과 조합을 상징하는 진정한 ‘Next Generation Brand’를 목표합니다. 또한, 전세계 최초로 Photo로 촬영된 실물 파츠들을 랜더마이즈하여, 메타버스 뿐만 아니라 현실(오프라인)에서 사용
                            가능하고 실현 가능한 패션 브랜드입니다.<br/>
                            <span className='bold'>Utility </span>: Web 3.0상에서만 가능한 DAO를 통해 전 세계적으로 다른 문화, 인종, 유행 등을 가진
                            홀더들이 함께 만들어 나가는 패션 브랜드를 위한
                            Membership NFT입니다.<br/>
                            <span className='bold'>Vision </span>: 기존 Web 2.0 시대의 ‘연결’을 넘어 Web 3.0에서 모두가 ‘참여하고 공정한 수익이
                            배분’되는 여러분들의 브랜드, 같이 만들어 보면
                            어떨까요?
                        </div>
                    </FAQItem>
                    <FAQItem
                        isMobile={isMobileVal}
                        itemIndex={2}
                        selectedIndex={expandIndex}
                        title={'민팅 날짜와 수량, 가격은 어떻게 되나요?'}
                        setExpandIndex={setExpandIndex}
                    >
                        <div className='description'>
                            민팅 날짜는 추후에 해당 웹사이트 및 Discord 및 Twitter에 발표 예정입니다.<br/>
                            민팅 수량은 12,000개입니다. 선제적으로 민팅을 할 수 있는 WL(White List)에 대한 정보 및 지갑 당 민팅 가능한 수량은 Discord 및
                            Twitter에 게시될 예정입니다.<br/>
                            민팅 가격은 탈중앙화 정신에 따라 운영진이 정하는 것이 아닌, 5개의 NFT를 디스코드 참여자들에게 경쟁 입찰하고 낙찰가의 평균 가격으로 민팅 예정입니다.<br/>
                            아래의 링크로 Task를 완료하여 Discord, Twitter 등을 연동해주세요!<br/>
                            https://www.premint.xyz/
                        </div>
                    </FAQItem>
                    <FAQItem
                        isMobile={isMobileVal}
                        itemIndex={3}
                        selectedIndex={expandIndex}
                        title={'NFT의 희귀도(Rarity)가 있나요? 어디서 확인할 수 있나요?'}
                        setExpandIndex={setExpandIndex}
                    >
                        <div className='description'>
                            Kita’s Friends NFT는 착용한 악세사리, 헤어 등에 따른 희귀도가 존재합니다. 희귀도에 따른 보상 및 Governance의 참여 권한 등은 추후에 공개될
                            예정입니다.<br/>
                            Kita’s Friends NFT는 희귀도는 민팅 후, 약 1개월 이내에 해당 웹사이트 및 Rarity Tools에 표시될 예정입니다.
                        </div>
                    </FAQItem>
                    <FAQItem
                        isMobile={isMobileVal}
                        itemIndex={4}
                        selectedIndex={expandIndex}
                        title={'Kita’s Friends NFT의 Body는 누구인가요?'}
                        setExpandIndex={setExpandIndex}
                    >
                        <div className='description'>
                            Kita’s Friends NFT의 Body는 아이돌 그룹 달샤벳의 멤버로 활동 했던 ‘수빈’ 님입니다.<br/>
                            ‘수빈’ 님은 약 4년 전부터 국내 최초의 부캐(<a target='_blank'
                                                          href='https://www.instagram.com/2nd.life.nikita/'>https://www.instagram.com/2nd.life.nikita</a>)
                            제작 등 다양한 메타버스적인 아이디어에 대한 관심 및 실행을 통해 다양한 실험을 하셨기 때문에 해당 프로젝트의 모델로서 가장 적합하다고 판단하였습니다.<br/>
                            또한, ‘수빈’ 님은 해당 프로젝트의 모델뿐만 아니라 1명의 파운더로서 Web 3.0의 Creator Economy에 대한 이해 및 가능성을 바탕으로 해당 프로젝트
                            기획에 참여하셨으며, 스타가 만들어가는 생태계가 아닌 모든 홀더들이 참여하고 만들어 갈 수 있는 새로운 생태계를 꿈꿉니다.
                        </div>
                    </FAQItem>
                    <FAQItem
                        isMobile={isMobileVal}
                        itemIndex={5}
                        selectedIndex={expandIndex}
                        title={'지속적인 가치 유지가 가능한가요 ?'}
                        setExpandIndex={setExpandIndex}
                    >
                        <div className='description'>
                            가장 먼저 Kita’s Friends NFT는 전 세계 최초의 실물 파츠를 촬영하여 매칭한 Photo NFT입니다. 이는 메타버스 뿐만 아니라 현실에서도 제작 및
                            착용 가능하며, 오히려 메타버스에서 창조 된 패션을 현실로 옮겨오는 새로운 시도를 표현하는 창조적인 Art로 가치를 유지 할 것 입니다.<br/>
                            두번째로 Kita’s Friends NFT는 단순히 PFP를 넘어, 현실에서의 브랜드 런칭 및 수익 배분 뿐만 아니라, 거버넌스(DAO), 토큰 이코노미 등 다양한
                            Web 3.0의 Creator Economy를 기반으로 한 로드맵을 준비 중입니다. 더 이상 아무 의미 없는 NFT Collection은 불필요 합니다. Kita’s
                            Friends NFT가 국내 최초로 우리의 실생활에 녹아드는 유틸리티(의식주)를 제공하는 커뮤니티 회원권 형식의 NFT로 발전한다면, Art만을 뛰어 넘는 지속적인
                            가치를 줄 수 있다고 확신합니다.<br/>
                            마지막으로 Kita’s Friends NFT의 세계관은 여기가 끝이 아닙니다. Kita’s Friends NFT에는 총 12명의 친구들이 등장하며, 이들은 모두
                            각각의 NFT 콜렉션으로 진화 할 것이며, 메타버스와 현실을 잇는 새로운 패션 브랜드의 성장은 Kita의 세계관과 함께 성장 할것입니다.
                        </div>
                    </FAQItem>
                </FAQWrapper>
            </PageWrapper>
        </FAQSection>
    )
};

export default FAQ;