import {Section, TitleBox, Wrapper} from "../../components/Common";
import styled from "styled-components";
import CollectionSwiper from "../../components/Home/CollectionSwiper";
import {useEffect, useRef, useState} from "react";
import {useMediaQuery} from "react-responsive";
import {MOBILE_QUERY} from "../../utils/Utils";

const PageWrapper = styled.div`{
  position: relative;
  width: 100vw;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}`;

const UtilitySection = styled(Section)<{isMobile: boolean}>`{
  position: relative;
  width: 100vw;
  ${p=>p.isMobile ? `
  height: auto;
  ` : `
  height: 548px;
  `}
  overflow: hidden;
}`;
const Background = styled.img<{isMobile: boolean}>`{
  position: absolute;
  top: -90px;
  left: 0;
  width: 100%;
  ${p=>p.isMobile ? `
  height: 500px;
  ` : `
  height: 588px;
  `}
  
  object-fit: cover;
  object-position: center bottom;
}`;

const ContentBox = styled.div<{ isMobile: boolean }>`{
  ${p => p.isMobile ? `
  position: relative;
  margin-top: 20px;
  width: 272px;
  height: 188px;
  ` : `
  position: absolute;
  top: 70px;
  width: 347px;
  height: 240px;
  `}
  
  background: rgba(255, 255, 255, 0.5);
  border: 2px solid #FFFFFF;
  box-shadow: 0px 8px 52px -10px rgba(0, 0, 0, 0.14);
  backdrop-filter: blur(80px);
  border-radius: 12px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
  z-index: 2;


  > .title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    ${p => p.isMobile ? `
    font-size: 30px;
    ` : `
    font-size: 48px;
    `}
    
    line-height: 150%;
    text-align: center;
    color: #062460;
  }

  > .description {
    margin-top: 10px;
    font-family: 'Pretendard';
    font-style: normal;
    font-weight: 500;
    ${p => p.isMobile ? `
    font-size: 14px;
    ` : `
    font-size: 16px;
    `}
    line-height: 150%;
    text-align: center;
    letter-spacing: -0.005em;
    color: #062460;
  }
}`;

const ContentWrapper = styled.div<{ isMobile: boolean }>`{
  ${p => p.isMobile ? `
  width: 100%;
  position: static;
  margin-top: 22px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content; center;
  ` : `
  margin-top: 60px;
  width: 1080px;
  height: 240px;
  position: relative;
  >.first {
    left: 0;
  }
  >.second {
    left: 365px;
  }
  >.third {
    right: 0;
  }
  `}

}`

const Description = styled.div<{isMobile: boolean}>`{
  ${p => p.isMobile ? `
  padding: 0 20px;
  word-break: keep-all;
  ` : `
    
  `}
  margin-top: 30px;
  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  letter-spacing: -0.005em;
  color: #062460;
  opacity: 0.8;
}`;

const Utility = (props: any) => {
    const isMobileVal = props.isMobileVal;
    const utilitySectionRef = useRef<HTMLDivElement>(null);
    const [scrollPercentage, setScrollPercentage] = useState(0);
    useEffect(() => {
        const onScroll = (event: any) => {
            if (utilitySectionRef && utilitySectionRef.current) {
                const sectionTop = utilitySectionRef.current.offsetTop;
                const sectionHeight = utilitySectionRef.current.offsetHeight;
                const currentScrollValue = event.target.documentElement.scrollTop - sectionTop + window.innerHeight - sectionHeight / 3;
                const scrollValue = currentScrollValue > 0 ? ((currentScrollValue > sectionHeight) ? sectionHeight : currentScrollValue) : 0
                setScrollPercentage(scrollValue / sectionHeight * 100)
            }
        };
        window.addEventListener("scroll", onScroll);
        return () => window.removeEventListener("scroll", onScroll);
    }, [scrollPercentage]);
    return (
        <UtilitySection ref={utilitySectionRef} isFull={false} paddingTop={120} paddingBottom={100} isMobile={isMobileVal}>
            <Background src={'/img/home/utility/bg.svg'} isMobile={isMobileVal}/>
            <PageWrapper>
                <TitleBox className='wow fadeInUp' margin={18} isMobile={isMobileVal}>
                    <div className='title'>
                        NFT Utility
                    </div>
                    <div className='sub-title-m'>
                        Kita’s Friends NFT 홀더들은 전세계 최초로 DAO를 통한 패션 브랜드를 런칭합니다.
                    </div>
                </TitleBox>
                <ContentWrapper isMobile={isMobileVal}>
                    <ContentBox className='first wow fadeInUp' isMobile={isMobileVal} style={{
                        top: isMobileVal ? 0 : `${scrollPercentage * .8 < 0 ? 0 : 80 - scrollPercentage * .8}px`
                    }}>
                        <div className='title'>1</div>
                        <div className='description'>
                            Kita’s Friends 패션 브랜드의<br/>
                            브랜딩(로고, 스타일, 컬러 등)<br/>
                            전반의 결정
                        </div>
                    </ContentBox>
                    <ContentBox className='second wow fadeInUp' isMobile={isMobileVal} style={{
                        top: isMobileVal ? 0 : `${scrollPercentage * 2 < 0 ? 0 : 200 - scrollPercentage * 2}px`
                    }}>
                        <div className='title'>2</div>
                        <div className='description'>
                            분기마다 브랜드에서 런칭되는<br/>
                            상품(카테고리, 디자인, 컬러 등)<br/>
                            결정
                        </div>
                    </ContentBox>
                    <ContentBox className='third wow fadeInUp' isMobile={isMobileVal} style={{
                        top: isMobileVal ? 0 :  `${scrollPercentage * 1.1 < 0 ? 0 : 110 - scrollPercentage * 1.1}px`
                    }}>
                        <div className='title'>3</div>
                        <div className='description'>
                            월별 지급되는<br/>
                            브랜드 수익 배분 관련
                        </div>
                    </ContentBox>
                </ContentWrapper>
                <Description className='wow fadeInUp' isMobile={isMobileVal}>
                    이는 Governance, gOAP, OAP, Staking, Defi 등<br/>
                    web 3.0의 다양한 개념들을 순차적으로 적용하여 실행 예정입니다.
                </Description>
            </PageWrapper>
        </UtilitySection>
    )
};

export default Utility;