import SwiperCore, {Autoplay, Pagination} from "swiper";
import {Swiper, SwiperSlide} from 'swiper/react';
import styled from "styled-components";
import {useEffect, useState} from "react";

SwiperCore.use([Pagination, Autoplay]);

const MobileMainBackgroundSwiperWrapper = styled.div`{
  margin-top: 30px;
  width: 100vw;
  height: 100vh;


  .swiper-wrapper {
    transition-timing-function: ease-in-out;
    -webkit-transform-style: preserve-3d;
  }

  .swiper-slide {
    width: 100vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: fit-content;
    height: 100vh;
    margin: 0px 10px;
  }
}`;
const ImageViewWrapper = styled.div<{ isShow: boolean }>`{

  width: 100vw;
  height: 100vh;
  background: #CDADF6;

  > .photo {
    transition: all .7s;
    top: 0;
    left: 0;
    object-fit: cover;
    width: 100vw;
    height: 100vh;
    ${p => p.isShow ? `
    mix-blend-mode: initial;
    opacity: 1;
    filter: none;
    ` : `
    mix-blend-mode: luminosity;
    opacity: 0.5;
    filter: blur(5.20833px);
    `}

  }
}`
const MobileMainBackgroundSwiper = () => {
    const [clickedItem, setClickedItem] = useState(-1);
    return <MobileMainBackgroundSwiperWrapper>
        <Swiper
            slidesPerView={'auto'}
            spaceBetween={0}
            loop={true}
            speed={1000}
            freeMode={false}
            allowTouchMove={true}
            centeredSlides={true}
            loopedSlides={1}
            autoplay={{
                delay: 1000,
                disableOnInteraction: false,
                waitForTransition: true,
                pauseOnMouseEnter: false,
            }}
        >
            {
                Array.from({length: 13}, (v, i) => i).map((item, idx) => {
                    return <SwiperSlide key={idx} onClick={() => {
                        if(clickedItem === item){
                            setClickedItem(-1)
                        }else {
                            setClickedItem(item)
                        }
                    }}>
                        <ImageViewWrapper isShow={clickedItem === item}>
                            <img src={`/img/home/main/${item + 1}.jpeg`} className={'photo'}/>
                        </ImageViewWrapper>
                    </SwiperSlide>
                })
            }
        </Swiper>
    </MobileMainBackgroundSwiperWrapper>
}
export default MobileMainBackgroundSwiper;