import {GradientButton, Section, TitleBox, Wrapper} from "../../components/Common";
import styled from "styled-components";
import CollectionSwiper from "../../components/Home/CollectionSwiper";
import {useEffect, useMemo, useRef, useState} from "react";
import {useMediaQuery} from "react-responsive";
import {MOBILE_QUERY} from "../../utils/Utils";

const PageWrapper = styled.div`{
  position: relative;
  width: 100vw;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}`;

const ContentBox = styled.div<{ isMobile: boolean }>`{
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;

  background: #FFFFFF;
  border-radius: 12px;
  box-sizing: border-box;
  height: 100%;
  position: relative;
  
  ${p => p.isMobile ? `
  margin-top: 90px;
  padding: 0px 18px 40px;
  width: 100%;
  justify-content: center;
  ` : `
  justify-content: flex-start;
  padding: 0px 20px 50px;
  width: 530px;
  margin: 0 10px;
  &:first-child {
    margin-left: 0;
  }
 
  &:last-child {
    margin-right: 0;
  }
  `}
  > .logo {
    position: absolute;
    top: -60px;
    width: 120px;
    height: 120px;
  }

  > .title {
    margin-top: 84px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 150%;
    text-align: center;
    letter-spacing: -0.005em;
    color: #062460;
  }

  > .sub-title {
    box-sizing: border-box;
    ${p => p.isMobile ? `
    width: fit-content;
    padding: 0px 35px 22px;
    ` : `
    width: 420px;
    padding: 0px 35px 22px;
    `}

    margin-top: 12px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 120%;
    text-align: center;
    color: #062460;

    border-bottom: 1px solid #B1C8E2;

  }

  > .description {
    ${p => p.isMobile ? `
    padding: 0px 18px;
    font-size: 14px;
    ` : `
    font-size: 16px;
    `}
    margin: 30px 0 0;
    font-family: Pretendard;

    font-weight: 500;
    line-height: 26px;
    letter-spacing: -0.005em;
    text-align: left;
    color: #062460;
  }
}`;

const ContentWrapper = styled.div<{ isMobile: boolean }>`{
  margin-top: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${p => p.isMobile ? `
  padding: 0 24px;
  flex-direction: column;
  height: auto;
  ` : `
  flex-direction: row;
  height: 487px;
  `}
}`

const Description = styled.div<{ isMobile: boolean }>`{
  font-family: Poppins;
  ${p => p.isMobile ? `
  margin-top: 60px;
  font-size: 16px;
  padding: 0 24px;
  word-break: keep-all;
  ` : `
  margin-top: 80px;
  font-size: 24px;  
  `}

  font-weight: 600;
  line-height: 36px;
  letter-spacing: -0.005em;
  text-align: center;
  color: #062460;
}`;
const CardArea = styled.div<{ isMobile: boolean }>`{
  position: relative;
  width: 100%;
  box-sizing: border-box;
  ${p => p.isMobile ? `
  height: fit-content;
  margin-top: 30px;
  display: grid;
  overflow: hidden;
  padding: 0 24px 40px;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: auto;
  grid-column-gap: 10px;
  grid-row-gap: 20px;
  justify-items: center;
  max-width: 100%;
  ` : `
  height: 830px;
  `}

  overflow: hidden;
}`
const CardWrapper = styled.div`{
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-top: 60px;
  left: 50%;
  transform: translate(-50%, 0);
}`
const CardVerticalWrapper = styled.div`{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin: 0 33px;

  &:first-child {
    margin-top: 120px;
    margin-left: 0;
  }

  &:last-child {
    margin-top: 70px;
    margin-right: 0;
  }
}`

const Card = styled.div<{ isMobile: boolean }>`{

  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  
  ${p => p.isMobile ? `
  word-break: keep-all;
  width: 100%;
  display: inline-flex;
  padding: 30px 20px;
  min-height: 120px;
  height: 100%;
  font-size: 14px;
  line-height: 160%;
  ` : `
  display: flex;
  width: 300px;
  min-height: 200px;
  padding: 40px;
  margin: 20px 0;
  font-size: 16px;
  line-height: 150%;
  `}
  background: rgba(255, 255, 255, 0.8);
  border: 2px solid #FFFFFF;
  box-shadow: 0px 8px 52px -10px rgba(0, 0, 0, 0.14);
  backdrop-filter: blur(80px);
  border-radius: 12px;
  box-sizing: border-box;
  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 500;

  letter-spacing: -0.005em;
  color: #062460;

  text-align: left;

  > img {
    margin-bottom: 20px;
  }
  >.ic-text {
    margin-bottom: 10px;
    > .bold {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 600;
      line-height: 150%;
      text-align: center;
      color: #062460;
      ${p => p.isMobile ? `
    font-size: 36px;    
    ` : `
    font-size: 48px;
    `}
    }
    > .percent {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 600;
      line-height: 150%;
      color: #062460;
      ${p => p.isMobile ? `
    font-size: 22.5px;
    ` : `
    font-size: 30px;
    `}
    }
  }

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
}`;


const Tokenomics = (props: any) => {
    const isMobileVal = props.isMobileVal;
    const sectionRef = props.innerRef
    const [scrollPercentage, setScrollPercentage] = useState(0);
    useEffect(() => {
        const onScroll = (event: any) => {
            if (sectionRef && sectionRef.current) {
                const sectionHeight = 803;
                const sectionTop = sectionRef.current.offsetTop + (803 * 0.8);
                const currentScrollValue = event.target.documentElement.scrollTop - sectionTop + window.innerHeight - sectionHeight / 2;
                const scrollValue = currentScrollValue > 0 ? ((currentScrollValue > sectionHeight) ? sectionHeight : currentScrollValue) : 0
                setScrollPercentage(scrollValue / sectionHeight * 100)
            }
        };
        window.addEventListener("scroll", onScroll);
        return () => window.removeEventListener("scroll", onScroll);
    }, [scrollPercentage]);

    const CardContent1 = ({isMobile}: { isMobile: boolean }) => {
        return <>
            <Card isMobile={isMobile}>
                <img src={`/img/home/tokenomics/ic_money${isMobile ? '_m' : ''}.svg`}/>
                브랜드 Gov Treasury의 수익 및 {!isMobile && <br/>}gOAP 락업 비율에 따라 OAP 지급
            </Card>
            <Card isMobile={isMobile}>
                <img src={`/img/home/tokenomics/ic_airdrop${isMobile ? '_m' : ''}.svg`}/>
                본인이 소유한 NFT {!isMobile && <br/>}메타버스 아바타 에어드롭
            </Card>
        </>
    }
    const CardContent2 = ({isMobile}: { isMobile: boolean }) => {
        return <>
            <Card isMobile={isMobile}>
                <div className='ic-text'>
                    <span className='bold'>50</span>
                    <span className='percent'>%</span>
                </div>
                NFT 스테이킹 시, {!isMobile && <br/>}브랜드 상품 상시 50% 할인
            </Card>
            <Card isMobile={isMobile}>
                <div className='ic-text'>
                    <span className='bold'>NEW</span>
                </div>
                신규 아이템 구매 및{!isMobile && <br/>}
                장착 가능
            </Card>
            <Card isMobile={isMobile}>
                <img src={`/img/home/tokenomics/ic_nftitem${isMobile ? '_m' : ''}.svg`}/>
                NFT 프린트 패션 아이템 {!isMobile && <br/>}제작 및 지급
            </Card>
        </>
    }
    const CardContent3 = ({isMobile}: { isMobile: boolean }) => {
        const cardArr = [
            <Card isMobile={isMobile} key={1}>
                <div className='ic-text'>
                    <span className='bold'>10</span>
                    <span className='percent'>%</span>
                </div>
                NFT 스테이킹 시, 브랜드 상품을 {!isMobile && <br/>}NFT 홀더에게 지급된 개별 링크를 {!isMobile && <br/>}통해 판매 건마다 10%의 {!isMobile && <br/>}판매 수수료 지급
            </Card>,
            <Card isMobile={isMobile} key={2}>
                <img src={`/img/home/tokenomics/ic_gift${isMobile ? '_m' : ''}.svg`}/>
                Kita’s Friends NFT 굿즈 및{!isMobile && <br/>}촬영 소품 랜덤박스 배송
            </Card>
        ]
        return <>
            { isMobile ? cardArr.reverse().map((item => {
                return item
            })) : cardArr.map((item => {
                return item
            })) }
        </>
    }
    return (
        <Section ref={props.innerRef} isFull={false} paddingTop={100} paddingBottom={200}>
            <PageWrapper>
                <TitleBox className='wow fadeInUp' isMobile={isMobileVal}>
                    <div className='title'>
                        Tokenomics
                    </div>
                </TitleBox>
                <ContentWrapper isMobile={isMobileVal}>
                    <ContentBox className='wow fadeInUp' isMobile={isMobileVal}>
                        <img className='logo' src='/img/home/tokenomics/gOAP.png'/>
                        <div className='title'>gOAP</div>
                        <div className='sub-title'>Governance Token</div>
                        <ul className='description'>
                            <li>프렌즈 각각의 속성을 갖고 있는 ERC20 토큰</li>
                            <li>스테이킹한 NFT마다 동일한 프렌즈의 DAO 거버넌스 토큰인 gOAP를<br/>10개씩 부여한다.</li>
                            <li>gOAP는 Governance Pool에 락업 가능하다.<br/>
                                - 락업 시, Gov Treasury의 수익 및 gOAP 락업 비율에 따라 OAP 지급<br/>
                                - 락업 수 만큼 동시에 여러 Vote Pool 투표 가능<br/>
                                - 락업 비율에 따라 추후 원하는 프렌즈 컬렉션의 WL 신청 가능
                            </li>
                            <li>gOAP가 미사용중(락업 해제)일 때만 NFT 스테이킹을 풀 수 있으며,<br/>제네시스 NFT 스테이킹이 풀리면 gOAP는 소각된다.</li>
                        </ul>
                    </ContentBox>
                    <ContentBox className='wow fadeInUp' isMobile={isMobileVal}>
                        <img className='logo' src='/img/home/tokenomics/OAP.png'/>
                        <div className='title'>OAP</div>
                        <div className='sub-title'>Utility Token</div>
                        <ul className='description'>
                            <li>범용적으로 사용되는 ERC20 - 유틸리티 토큰</li>
                            <li>언제든 타 토큰 및 현금으로 전환할 수 있게 다양한 거래소에 상장 예정</li>
                            <li>메타버스 및 현실세계에서 재화로 활용된다.<br/>
                                - OAP defi(스테이킹 및 이자지급)<br/>
                                - 추후 Kita’s Friends에 등장하는 각각의 친구들 NFT 컬렉션 구매 가능<br/>
                                - OA 행성을 포함한 다양한 프렌즈 멀티버스 행성 내 땅 구매 가능
                            </li>
                        </ul>
                    </ContentBox>
                </ContentWrapper>
                <Description className='wow fadeInUp' isMobile={isMobileVal}>
                    궁극적으로 Kita’s Friends 홀더들의 DAO를 통해 패션 브랜드 및 상품을 제작합니다.
                </Description>
                <CardArea isMobile={isMobileVal}>
                    {
                        isMobileVal ? <>
                            <CardContent2 isMobile={true}/>
                            <CardContent1 isMobile={true}/>
                            <CardContent3 isMobile={true}/>
                        </> : <>
                            <CardWrapper>
                                <CardVerticalWrapper style={{
                                    marginTop: `${120 + (scrollPercentage * .8 < 0 ? 0 : 80 - scrollPercentage * .8)}px`
                                }}>
                                    <CardContent1 isMobile={false}/>
                                </CardVerticalWrapper>
                                <CardVerticalWrapper style={{
                                    marginTop: `${scrollPercentage * 2 < 0 ? 0 : 200 - scrollPercentage * 2}px`
                                }}>
                                    <CardContent2 isMobile={false}/>
                                </CardVerticalWrapper>
                                <CardVerticalWrapper style={{
                                    marginTop: `${70 + (scrollPercentage * 1.1 < 0 ? 0 : 110 - scrollPercentage * 1.1)}px`
                                }}>
                                    <CardContent3 isMobile={false}/>
                                </CardVerticalWrapper>
                            </CardWrapper>
                        </>
                    }
                </CardArea>
                <GradientButton className='wow fadeInUp' marginTop={20}>
                    <span>더 자세한 사항은 White Paper</span>
                </GradientButton>
            </PageWrapper>
        </Section>
    )
};

export default Tokenomics;