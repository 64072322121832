import styled from "styled-components";

import 'swiper/css';

import SwiperCore, {Autoplay, Pagination} from "swiper";
import {Swiper, SwiperSlide} from 'swiper/react';
import {useEffect} from "react";
import {useMediaQuery} from "react-responsive";
import {MOBILE_QUERY} from "../../utils/Utils";

SwiperCore.use([Pagination, Autoplay]);

const MarqueeWrapper = styled.div<{ mode: string, degree: number }>`{
  position: absolute;

  height: 60px;

  z-index: 2;

  ${p => p.mode === 'main' && `
    width: 100vw;
    bottom: 0;
    background: linear-gradient(90deg, #CCF4E9 0%, #C7B0ED 32.81%, #D7B2E2 56.25%, #FEE1C1 75.52%, #FFF7B6 100%);
  `}
  ${p => p.mode === 'minting' && `
    width: 100vw;
    z-index: 0;
    bottom: 180px;
    background: white;
    
  `}
  ${p => p.mode === 'collection' && `
    width: 120vw;
    margin-top: -70px;
    transform: translate(-50%, 0) rotate(${p.degree}deg);
    background: linear-gradient(90deg, #CCF4E9 0%, #C7B0ED 32.81%, #D7B2E2 56.25%, #FEE1C1 75.52%, #FFF7B6 100%);
    left: 50%;
  `}
  .swiper-wrapper {
    transition-timing-function: linear;
    -webkit-transform-style: preserve-3d;
  }

  .swiper-slide {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: fit-content;
    height: 60px;
    margin: 0px 5px;
  }
}`;

const MarqueeItem = styled.div`{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  font-family: Soloist;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  color: #062460;

  > .star {
    width: 31px;
    height: 42px;
    margin-left: 10px;
    animation: rotation 5s infinite linear;
  }

  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
}`

const Marquee = ({mode = 'main', width = 0}) => {
    const isMobileVal = useMediaQuery({
        query: MOBILE_QUERY
    });
    return <MarqueeWrapper mode={mode} degree={isMobileVal ? Math.atan2(80, width) * 45 : Math.atan2(328, width) * 45}>
        <Swiper
            slidesPerView={'auto'}
            spaceBetween={0}
            loop={true}
            speed={5000}
            freeMode={true}
            allowTouchMove={false}
            centeredSlides={true}
            loopedSlides={10}
            autoplay={{
                delay: 1,
                disableOnInteraction: true,
                waitForTransition: false
            }}
        >
            {
                Array.from({length: 10}, (v, i) => i).map((item, idx) => {
                    return <SwiperSlide key={idx}>
                        <MarqueeItem>
                            COLLECTION DROPPING SOON
                            {
                                mode !== 'minting' && <img className='star' src={'/img/home/main/star.svg'}/>
                            }
                        </MarqueeItem>
                    </SwiperSlide>
                })
            }
        </Swiper>
    </MarqueeWrapper>
}

export default Marquee;