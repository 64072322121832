import {Wrapper} from "./Common";
import {MOBILE_QUERY} from "../utils/Utils";
import {useMediaQuery} from "react-responsive";
import styled from "styled-components";
import {Link} from "react-router-dom";
import React, {MutableRefObject, useEffect, useState} from "react";
import roadmap from "../pages/Home/Roadmap";

const TopBarWrapper = styled.div<{ isMobile: boolean, isDock: boolean }>`
  position: fixed;
  width: 100vw;
  z-index: 10;
  ${p => p.isMobile ? `
    
  ` : `
    min-width: 1240px;
  `}

  height: 60px;

  z-index: 99;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  transition: all .2s;

  ${p => p.isDock ? `
    top: 0;
    background: rgba(24, 24, 24, 0.2);
    backdrop-filter: blur(20px);
  ` : `
    top: 30px;
  `}
`

const TopBarContent = styled.div<{ isMobile: boolean }>`
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  position: relative;

  ${p => p.isMobile ? `
    padding: 0 25px;
  ` : `
  `}
  .logo {
    ${p => p.isMobile ? `
    margin-top: 11px;
    width: 157px;
    height: 35px;
  ` : `
    margin-top: 11px;
    width: 226px;
    height: 49px;
  `}
  }
`

const TopBarMenus = styled.div<{}>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  > a {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

const TopBarMenuItem = styled.div`
  display: flex;
  padding: 0px 27px;

  > a {
    font-family: Pretendard;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.005em;
    text-align: center;
    color: #FFFFFF;
    text-decoration: none;
    transition: all .2s;

    &:hover {
      color: #FFEAA0;
    }
  }

  > .selected {
    color: #FFEAA0;
  }

  &:last-child {
    padding-right: 0px;
  }
`

const LanguageSelector = styled.div<{isMobile: boolean}>`
  cursor: pointer;
  border: 0.5px solid #959595;
  border-radius: 8px;
  
  
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  ${p => p.isMobile ? `
  width: 78px;
  height: 28px;
  font-size: 12px;
  line-height: 12px;
  padding: 0px 0px;
  ` : `
  width: 100px;
  height: 36px;
  margin-left: 80px;
  font-size: 14px;
  line-height: 14px;
  padding: 0px 10px;
  `}
  
  font-family: Pretendard;
  font-weight: 400;
  letter-spacing: -0.005em;
  text-align: center;
  color: #FFFFFF;

  > .earth {
    ${p => p.isMobile ? `
    width: 14px;
    height: 14px;
    margin-right: 6px;
    ` : `
    width: 14px;
    height: 14px;
    margin-right: 8px;
    `}
  }

  > .arrow {
    ${p => p.isMobile ? `
    width: 8px;
    height: 4px;
    margin-left: 6px;
    ` : `
    width: 10px;
    height: 6px;
    margin-left: 19px;
    `}
   
  }
`

const TopBar = ({
                    mainRef, aboutRef, nftRef, tokenomicsRef, mintingRef, roadMapRef, teamRef, faqRef
                }: {
    mainRef: MutableRefObject<HTMLDivElement | undefined>
    aboutRef: MutableRefObject<HTMLDivElement | undefined>
    nftRef: MutableRefObject<HTMLDivElement | undefined>
    tokenomicsRef: MutableRefObject<HTMLDivElement | undefined>
    mintingRef: MutableRefObject<HTMLDivElement | undefined>
    roadMapRef: MutableRefObject<HTMLDivElement | undefined>
    teamRef: MutableRefObject<HTMLDivElement | undefined>
    faqRef: MutableRefObject<HTMLDivElement | undefined>
}) => {
    const isMobileVal = useMediaQuery({
        query: MOBILE_QUERY
    });
    const [scrollTop, setScrollTop] = useState(0);
    const [isDock, setIsDock] = useState(false)
    const [currentSection, setCurrentSection] = useState('')
    useEffect(() => {
        const onScroll = (event: any) => {
            if (aboutRef.current && nftRef.current && tokenomicsRef.current && roadMapRef.current && teamRef.current && faqRef.current) {

                const aboutTop = aboutRef.current!!.offsetTop;
                const nftTop = nftRef.current!!.offsetTop;
                const tokenomicsTop = tokenomicsRef.current!!.offsetTop;
                const roadmapTop = roadMapRef.current!!.offsetTop;
                const teamTop = teamRef.current!!.offsetTop;
                const faqTop = faqRef.current!!.offsetTop;

                const currentScrollTop = event.target.documentElement.scrollTop
                setScrollTop(currentScrollTop)
                if (window.scrollY > 100) {
                    setIsDock(true)
                } else {
                    setIsDock(false)
                }
                const view = currentScrollTop + (window.innerHeight * 0.6);
                if (view > faqTop) {
                    setCurrentSection('faq')
                } else if (view > teamTop) {
                    setCurrentSection('team')
                } else if (view > roadmapTop) {
                    setCurrentSection('roadmap')
                } else if (view > tokenomicsTop) {
                    setCurrentSection('tokenomics')
                } else if (view > nftTop) {
                    setCurrentSection('nft')
                } else if (view > aboutTop) {
                    setCurrentSection('about')
                } else {
                    setCurrentSection('')
                }
            }
        };
        window.addEventListener("scroll", onScroll);
        return () => window.removeEventListener("scroll", onScroll);
    }, [scrollTop]);
    const scrollTo = (targetRef: React.MutableRefObject<HTMLDivElement | undefined>) => {
        if (targetRef && targetRef.current) {
            window.scrollTo(0, targetRef.current.offsetTop - 50)
        }
    }

    return isMobileVal ?
        <Wrapper>
            <TopBarWrapper isMobile={true} isDock={true}>
                <TopBarContent isMobile={true}>
                    <Link to="/"><img className="logo" src="/img/common/logo.svg"/></Link>
                    <LanguageSelector isMobile={true}>
                        <img className="earth" src="/img/common/ic_earth.svg"/>
                        ENG
                        <img className="arrow" src="/img/common/ic_arrow_down.svg"/>
                    </LanguageSelector>
                </TopBarContent>
            </TopBarWrapper>
        </Wrapper> :
        <Wrapper>
            <TopBarWrapper isMobile={true} isDock={isDock}>
                <TopBarContent isMobile={false}>
                    <Link to="/"><img className="logo" src="/img/common/logo.svg"/></Link>
                    <TopBarMenus>
                        <TopBarMenuItem>
                            <a className={currentSection === 'about' ? 'selected' : ''} onClick={() => {
                                scrollTo(aboutRef)
                            }}>ABOUT</a>
                        </TopBarMenuItem>
                        <TopBarMenuItem>
                            <a className={currentSection === 'nft' ? 'selected' : ''} onClick={() => {
                                scrollTo(nftRef)
                            }}>NFT</a>
                        </TopBarMenuItem>
                        <TopBarMenuItem>
                            <a className={currentSection === 'tokenomics' ? 'selected' : ''} onClick={() => {
                                scrollTo(tokenomicsRef)
                            }}>TOKENOMICS</a>
                        </TopBarMenuItem>
                        <TopBarMenuItem>
                            <a className={currentSection === 'roadmap' ? 'selected' : ''} onClick={() => {
                                scrollTo(roadMapRef)
                            }}>ROADMAP</a>
                        </TopBarMenuItem>
                        <TopBarMenuItem>
                            <a className={currentSection === 'team' ? 'selected' : ''} onClick={() => {
                                scrollTo(teamRef)
                            }}>TEAM</a>
                        </TopBarMenuItem>
                        <TopBarMenuItem>
                            <a className={currentSection === 'faq' ? 'selected' : ''} onClick={() => {
                                scrollTo(faqRef)
                            }}>FAQ</a>
                        </TopBarMenuItem>
                    </TopBarMenus>
                    <LanguageSelector isMobile={false}>
                        <img className="earth" src="/img/common/ic_earth.svg"/>
                        ENG
                        <img className="arrow" src="/img/common/ic_arrow_down.svg"/>
                    </LanguageSelector>
                </TopBarContent>
            </TopBarWrapper>
        </Wrapper>
}

export default TopBar;