import {ThemeProvider} from "styled-components";
import React, {useEffect} from 'react';
import theme from "./theme";
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import Container from "./Container";
import Home from "./pages/Home";
import NotFound from "./pages/NotFound";

function App() {
  return (
      <ThemeProvider theme={theme}>
          <BrowserRouter>
            <Container>
              <Routes>
                <Route path={'/'} element={<Home />} />
                <Route path={'*'} element={<NotFound />}/>
              </Routes>
            </Container>
          </BrowserRouter>
      </ThemeProvider>
  );
}

export default App;
