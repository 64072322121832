import styled from "styled-components";
import lottie, {AnimationItem} from "lottie-web";
import {useEffect, useRef, useState} from "react";

const MainLogoWrapper = styled.div`
  width: 831px;
  height: 297px;
  z-index: 3;
`;


const MainLogo = (props: any) => {
    const logoRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        if (logoRef.current) {
            const instance = lottie.loadAnimation({
                name: 'mainLogo',
                container: logoRef.current,
                renderer: 'svg',
                loop: true,
                autoplay: true,
                animationData: require('./main_logo.json'),
            })
            return () => instance.destroy();
        }
    }, [])
    return <MainLogoWrapper ref={logoRef}/>
}

export default MainLogo;