import {Sticker, StickerWrapper, Wrapper} from "../../components/Common";
import TopBar from "../../components/TopBar";
import Footer from "../../components/Footer";
import Main from "./Main";
import Friends from "./Friends";
import Collection from "./Collection";
import Utility from "./Utility";
import styled from "styled-components";
import Tokenomics from "./Tokenomics";
import Minting from "./Minting";
import Roadmap from "./Roadmap";
import Team from "./Team";
import FAQ from "./FAQ";
import Marquee from "../../components/Home/Marquee";
import {createRef, useEffect, useRef, useState} from "react";
import {useMediaQuery} from "react-responsive";
import {MOBILE_QUERY} from "../../utils/Utils";

const HomeWrapper = styled(Wrapper)`{
  position: relative;
}`;

const HomeBackground = styled.div`{
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100vw;
  height: 100vh;
  background: linear-gradient(180deg, #C5B2EE 9.1%, #FFF8B5 48.59%, #CEFAE9 85.68%);
}`
const Home = (props: any) => {
    const isMobileVal = useMediaQuery({
        query: MOBILE_QUERY
    });

    function getWindowDimensions() {
        const {innerWidth: width, innerHeight: height} = window;
        return {
            width,
            height
        };
    }

    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    const MainRef = useRef<HTMLDivElement>();
    const AboutRef = useRef<HTMLDivElement>();
    const NFTRef = useRef<HTMLDivElement>();
    const TokenomicsRef = useRef<HTMLDivElement>();
    const RoadMapRef = useRef<HTMLDivElement>();
    const MintingRef = useRef<HTMLDivElement>();
    const TeamRef = useRef<HTMLDivElement>();
    const FAQRef = useRef<HTMLDivElement>();
    return (
        <HomeWrapper>
            <TopBar mainRef={MainRef} aboutRef={AboutRef} nftRef={NFTRef} tokenomicsRef={TokenomicsRef}
                    roadMapRef={RoadMapRef} mintingRef={MintingRef} teamRef={TeamRef} faqRef={FAQRef}/>
            <Main isMobileVal={isMobileVal} innerRef={MainRef}/>
            <Friends isMobileVal={isMobileVal} innerRef={AboutRef}/>
            <Collection isMobileVal={isMobileVal} innerRef={NFTRef}/>
            <Marquee mode={'collection'} width={windowDimensions.width}/>
            <Utility isMobileVal={isMobileVal}/>
            <Tokenomics isMobileVal={isMobileVal} innerRef={TokenomicsRef}/>
            {
                !isMobileVal && <StickerWrapper height={212}>
                    <Sticker width={58} height={94} top={100} left={-10} src={'/img/home/sticker/minting_sticker.png'}/>
                </StickerWrapper>
            }
            <Minting isMobileVal={isMobileVal} innerRef={MintingRef}/>
            <Roadmap isMobileVal={isMobileVal} innerRef={RoadMapRef}/>
            {
                !isMobileVal && <StickerWrapper height={212}>
                    <Sticker width={212} height={212} top={-80} left={-60} src={'/img/home/sticker/team_sticker.png'}/>
                </StickerWrapper>
            }
            <Team isMobileVal={isMobileVal} innerRef={TeamRef}/>
            <FAQ isMobileVal={isMobileVal} innerRef={FAQRef}/>
            <Footer/>
            <HomeBackground/>
        </HomeWrapper>
    )
};

export default Home;
