import {GradientButton, Section, TitleBox, Wrapper} from "../../components/Common";
import styled from "styled-components";
import Marquee from "../../components/Home/Marquee";

const PageWrapper = styled.div`{
  position: relative;
  width: 100vw;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}`;

const Card = styled.div<{isMobile: boolean}>`{
  box-sizing: border-box;
  display: flex;
  
  
  background: rgba(255, 255, 255, 0.5);
  border: 2px solid #FFFFFF;
  box-shadow: 0px 8px 52px -10px rgba(0, 0, 0, 0.14);
  backdrop-filter: blur(80px);
  border-radius: 12px;
  
  text-align: left;

  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 600;
  
  line-height: 160%;
  letter-spacing: -0.005em;
  color: #062460;
  
  ${p => p.isMobile ? `
  font-size: 14px;
  width: calc(100% - 48px);
  padding: 57px 0px;
  margin-top: 30px;
  ` : `
  margin-top: 20px;
  width: 854px;
  font-size: 18px;
  padding: 46.5px 120px;
  `}
}`

const Minting = (props: any) => {
    const isMobileVal = props.isMobileVal;
    return (
        <Section ref={props.innerRef} isFull={false} paddingBottom={120}>
            <PageWrapper>
                <TitleBox className='wow fadeInUp' margin={18} isMobile={isMobileVal}>
                    <div className='title'>
                        Minting
                    </div>
                    <div className='sub-title-s-1'>
                        민팅 가격은 탈중앙화 정신에 따라 운영진이 정하는 것이 아닌,<br/>
                        5개의 NFT를 디스코드 참여자들에게 경쟁 입찰하고 낙찰가의 평균 가격으로 민팅 예정입니다.
                    </div>
                    <div className={`${isMobileVal ? 'sub-title-s-2' : 'sub-title-s-1'} margin-top-30`}>
                        민팅 날짜는 추후에 해당 웹사이트 및 디스코드 및 트위터에 발표될 예정입니다.
                    </div>
                </TitleBox>
                <Marquee mode={'minting'} width={10}/>
                <Card className='wow fadeInUp' isMobile={isMobileVal}>
                    <ul>
                        <li>가격 확정을 위한 경쟁 입찰 날짜:</li>
                        <li>WL 민팅 날짜(경쟁 입찰 평균가의 20% Discount):</li>
                        <li>Public 민팅 날짜(경쟁 입찰 평균가):</li>
                    </ul>
                </Card>
                <GradientButton className='wow fadeInUp' marginTop={40}>
                    <span>Task 완료하고 민팅 준비하기</span>
                </GradientButton>
            </PageWrapper>
        </Section>
    )
};

export default Minting;