import SwiperCore, {Autoplay, Pagination} from "swiper";
import {Swiper, SwiperSlide} from 'swiper/react';
import styled from "styled-components";

SwiperCore.use([Pagination, Autoplay]);


const CollectionSwiperWrapper = styled.div<{ isMobile: boolean }>`{
  ${p => p.isMobile ? `
  margin-top: 30px;
  width: 100vw;
  height: fit-content;
  ` : `
  margin-top: 30px;
  width: 100vw;
  height: 390px
  `}
  .swiper-wrapper {
    transition-timing-function: ease-in-out;
    -webkit-transform-style: preserve-3d;
  }

  .swiper-slide {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    ${p => p.isMobile ? `
    padding-top: 70px;
    width: min(280px, calc(100vw - 80px));
    height: 200px;
    ` : `
    width: 360px;
    height: 390px;
    margin: 0px 10px;  
    `}
  }

  .swiper-slide-active {
    ${p => p.isMobile ? `
    width: min(280px, calc(100vw - 80px));
    height: 310px;
    padding-top: 0px;
    .sticker {
      opacity: 1;
    }

    .layer {
      opacity: 0;
      left: 0;
    }

    .image {
      width: 280px;
      opacity: 1;
      left: 0;
    }
    ` : ``}
  }
}`;
const CollectionItem = styled.div<{ isMobile: boolean }>`{
  ${p => p.isMobile ? `
  width: 100%;
  height: 100%;
    ` : `
  width: 360px;
  height: 390px;  
    `}

  position: relative;

  > .image {

    position: absolute;
    bottom: 0;
    
    ${p => p.isMobile ? `
    width: 200px;
    left: 40px;
    height: min(280px, 100%);
    object-fit: contain;
    ` : `
    transition: all .3s;
    left: 0px;
    width: 360px;
    height: 360px;  
    object-fit: cover;
    `}


    border-radius: 8px;
    mix-blend-mode: luminosity;
    opacity: 0.5;
  }

  > .layer {
    position: absolute;
    bottom: 0;
    left: 0;
    ${p => p.isMobile ? `
    width: 200px;
    height: 200px;
    left: 40px;
    ` : `
    transition: all .3s;
    width: 360px;
    height: 360px;
    `}
    background: #BC9FE1;
    mix-blend-mode: hard-light;
    border-radius: 8px;
  }

  > .sticker {
    width: 100%;
    height: fit-content;
    
    position: absolute;

    ${p => p.isMobile ? `
    top: 0px;
    ` : `
    top: 0px;
    transition: all .3s;
    `}
    left: 50%;
    transform: translate(-50%, 0);
    opacity: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    > .sticker-bg {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, 0);
    }

    > .info {
      ${p => p.isMobile ? `
      top: 20px;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 150%;
      ` : `
      top: 27px;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 150%;
      `}
      text-align: center;
      color: #062460;
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0);
    }
  }

  ${p => p.isMobile ? `

    ` : `
  &:hover {
    > .sticker {
      opacity: 1;
    }

    > .layer {
      opacity: 0;
    }

    > .image {
      opacity: 1;
    }
  }
    `}

}`;

const CollectionSwiper = ({isMobile}: { isMobile: boolean }) => {
    const collectionImages = [
        '1_DAL.jpeg',
        '2_Margo.jpeg',
        '3_Matilda.jpeg',
        '4_Camila.jpeg',
        '5_Nessa.jpeg',
        '6_Pinch.jpeg',
        '7_Ada.jpeg',
        '8_Judy.jpeg',
        '9_Sienna.jpeg',
        '10_Union.jpeg',
        '11_Yvonne.jpeg',
        '12_Lilly.jpeg',
    ]
    return <CollectionSwiperWrapper isMobile={isMobile}>
        <Swiper
            slidesPerView={'auto'}
            spaceBetween={0}
            loop={true}
            speed={1000}
            freeMode={false}
            allowTouchMove={true}
            centeredSlides={true}
            loopedSlides={4}
            autoplay={{
                delay: 1000,
                disableOnInteraction: false,
                waitForTransition: true,
                pauseOnMouseEnter: true
            }}
        >
            {
                collectionImages.map((item, idx) => {
                    return <SwiperSlide key={idx}>
                        <CollectionItem className='wow fadeInUp' isMobile={isMobile}>
                            <div className='layer'/>
                            <img className='image' src={`/img/home/collection/${item}`}/>
                            <div className='sticker'>
                                <img className='sticker-bg'
                                     src={isMobile ? '/img/home/collection/banner_mobile.png' : '/img/home/collection/banner.png'}/>
                                <span className='info'>{item.split('_')[1].replace('.jpeg', '')} X 500</span>
                            </div>

                        </CollectionItem>
                    </SwiperSlide>
                })
            }
        </Swiper>
    </CollectionSwiperWrapper>
}
export default CollectionSwiper;