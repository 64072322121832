import {Section, Wrapper} from "../../components/Common";
import styled from "styled-components";
import MainLogo from "../../components/Lottie/MainLogo";
import Marquee from "../../components/Home/Marquee";
import {useMediaQuery} from "react-responsive";
import {MOBILE_QUERY} from "../../utils/Utils";
import MobileMainBackgroundSwiper from "../../components/Home/MobileMainBackgroundSwiper";

const BackgroundItem = styled.div`{
  transition: all .7s;
  width: calc(100vw / 13);

  > .item {
    width: 100%;
    height: 100%;
    object-fit: cover;
    mix-blend-mode: luminosity;
    filter: blur(15px);
  }

  &:hover {
    width: calc(100vw / 7);

    > .item {
      filter: none;
      mix-blend-mode: initial;
    }
  }
}`;

const Background = styled.div`{
  position: absolute;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: row;
  overflow: hidden;
}`;

const PageWrapper = styled.div`{
  position: relative;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}`;

const MobileBackgroundImageWrapper = styled.div`{
  
}`
const MobileLogo = styled.img`{
  z-index: 3;
}`

const Main = (props: any) => {
    const isMobileVal = props.isMobileVal;
    return (
        <Section ref={props.innerRef} isFull={true} backgroundColor={'#CDADF6'}>
            <PageWrapper>
                {
                    isMobileVal ?
                        <>
                            <Background>
                                <MobileMainBackgroundSwiper/>
                            </Background>
                            <MobileLogo src={'/img/home/main/mobile_logo.png'}/>
                        </> :
                        <>
                            <Background>
                                {
                                    Array.from({length: 13}, (v, i) => i).map((item, idx) => {
                                        return <BackgroundItem key={idx}>
                                            <img src={`/img/home/main/${item + 1}.jpeg`} className={'item'}/>
                                        </BackgroundItem>
                                    })
                                }
                            </Background>
                            <MainLogo/>
                        </>
                }
                <Marquee/>
            </PageWrapper>
        </Section>
    )
};

export default Main;