import {Section, TitleBox, Wrapper} from "../../components/Common";
import styled from "styled-components";
import MainLogo from "../../components/Lottie/MainLogo";
import Marquee from "../../components/Home/Marquee";
import {useState} from "react";
import {useMediaQuery} from "react-responsive";
import {MOBILE_QUERY} from "../../utils/Utils";

const PageWrapper = styled.div`{
  position: relative;
  width: 100vw;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}`;

const SNSWrapper = styled.div`{
  margin-top: 30px;
  height: 56px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;

  > .sns-button {
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .3s;
    margin-right: 10px;
    width: 48px;
    height: 48px;
    box-sizing: border-box;
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid #062460;
    border-radius: 38px;
    box-shadow: 0px 4px 0px #062460;

    &:hover {
      margin-bottom: 4px;
      box-shadow: 0px 8px 0px #062460;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}`;

const ContentWrapper = styled.div<{ isMobile: boolean }>`{
  margin-top: 60px;
  box-sizing: border-box;

  ${p => p.isMobile ? `
    display: flex;
    flex-direction: column;
    width: 100%;  
    position: static;
    align-items: center;
    justify-content: center;
    padding-left: 24px;
    padding-right: 24px;
    ` : `
    position: relative;
    width: 1440px;
    height: 560px;
  `}
  > .content-1 {
    ${p => p.isMobile ? `
    ` : `
    top: 170px;
    left: 290px;
    `}
  }

  > .content-2 {
    ${p => p.isMobile ? `
    margin-top: 30px;
    ` : `
    bottom: 160px;
    right: 270px;
    `}
  }

  .profileImg2 {
    position: relative;
    width: 70px;
    height: 70px;
    margin: 10px;
    border: 3px solid transparent;
    border-radius: 50%;
    background-image: linear-gradient(#fff, #fff),
    linear-gradient(to right, red 0%, orange 100%);
    background-origin: border-box;
    background-clip: content-box, border-box;
  }

  .content {
    ${p => p.isMobile ? `
    position: static;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-right: 24px;
    margin-left: 24px;
    height: fit-content;
    z-index: 1;
    ` : `
    position: absolute;
    `}
  }

  > .mobile-content-wrapper {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    padding: 30px 18px;

    > .mobile-content-background {
      box-sizing: border-box;
      position: absolute;
      z-index: 0;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 12px;

      background: linear-gradient(180deg, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0) 100%);
      border-radius: 8px;
      border: 2px solid #062460;
      border-bottom-width: 6px;
    }
  }

  > .margin-top-30 {
    margin-top: 30px;
  }

  .title {
    ${p => p.isMobile ? `
      max-width: 200px;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 150%;
      text-align: center; 
      color: #062460;
      ` : `
      font-family: Poppins;
      font-size: 28px;
      font-weight: 700;
      line-height: 42px;
      letter-spacing: 0em;
      text-align: center;
      color: #062460;
      `}
  }

  .sub-title {
    margin-top: 20px;
    ${p => p.isMobile ? `
       font-family: 'Pretendard';
       font-style: normal;
       font-weight: 500;
       font-size: 14px;
       line-height: 150%;
       letter-spacing: -0.005em;
       color: #062460;
       ` : `
      font-family: Pretendard;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: -0.005em;
      text-align: center;
      color: #062460;       
       `}
  }

  > .p1 {
    position: absolute;
    bottom: 0;
    left: 120px;
  }

  > .p2 {
    position: absolute;
    bottom: 7px;
    right: 180px;
  }

  > .left {
    position: absolute;
    top: 0;
    left: -90px;
  }

  > .right {
    position: absolute;
    top: 75px;
    right: -80px;
  }

}`;

const Friends = (props: any) => {
    const [cursor, setCursor] = useState({x: 0, y: 0})
    const isMobileVal = props.isMobileVal;
    const Content1 = () => {
        return <>
            <div className='title'>
                [GENESIS Project] Kita’s Friends
            </div>
            <div className='sub-title'>
                [OA 행성에 사는 키타의 친구들이 모인 제네시스 컬렉션]<br/>
                OA행성은 모두가 같은 바디를 가지고 있기 때문에<br/>
                아이덴티티를 표현할 수 있는 수단은 오.로.지 헤어와 패션 뿐!<br/><br/>
                어느 날, 행성 최대 인싸인 키타는 인싸력 1,000%인<br/>
                12명의 친구들을 모아 전 우주를 강타할 브랜드를<br/>
                만들기로 하는데...
            </div>
        </>
    }
    const Content2 = () => {
        return <>
            <div className='title'>
                Next...
            </div>
            <div className='sub-title'>
                키타의 친구들이<br/>
                각각 NFT 컬렉션 런칭 예정
            </div>
        </>
    }

    return (
        <Section ref={props.innerRef} isFull={false} paddingTop={isMobileVal ? 120 : 160} paddingBottom={isMobileVal ? 80 : 160}
                 onMouseMove={(event => {
                     const data = {
                         x: ((event.pageX / event.currentTarget.clientWidth * 100) - 50) / 10,
                         y: (((event.pageY - event.currentTarget.offsetTop) / event.currentTarget.clientHeight * 100) - 50) / 10
                     }
                     setCursor(data)
                 })}>
            <PageWrapper>
                <TitleBox className='wow fadeInUp' margin={10} isMobile={isMobileVal}>
                    <div className='title'>
                        KITA’S FRIENDS
                    </div>
                    <div className='sub-title-m'>
                        키타와 12명의 친구들이 OA 행성의 유행을 담은 브랜드를 런칭합니다.
                    </div>
                    <SNSWrapper className='wow fadeInUp'>
                        <div className='sns-button'>
                            <img
                                className='sns-button-image'
                                src={'/img/home/friends/ic_twitter.svg'}
                            />
                        </div>
                        <div className='sns-button'>
                            <img
                                className='sns-button-image'
                                src={'/img/home/friends/ic_discord.svg'}
                            />
                        </div>
                        <div className='sns-button'>
                            <img
                                className='sns-button-image'
                                src={'/img/home/friends/ic_medium.svg'}
                            />
                        </div>
                        <div className='sns-button'>
                            <img
                                className='sns-button-image'
                                src={'/img/home/friends/ic_telegram.svg'}
                            />
                        </div>
                        <div className='sns-button'>
                            <img
                                className='sns-button-image'
                                src={'/img/home/friends/ic_opensea.svg'}
                            />
                        </div>
                    </SNSWrapper>
                </TitleBox>
                <ContentWrapper isMobile={isMobileVal}>

                    {isMobileVal ? <>
                            <div className='mobile-content-wrapper'>
                                <div className='mobile-content-background'/>
                                <div className='wow fadeInUp content-1 content'>
                                    <Content1/>
                                </div>
                            </div>
                            <div className='mobile-content-wrapper margin-top-30'>
                                <div className='mobile-content-background'/>
                                <div className='wow fadeInUp content-2 content'>
                                    <Content2/>
                                </div>
                            </div>
                        </> :
                        <>
                            <img className='wow fadeInUp p1' src={'/img/home/friends/p1.png'} style={{
                                transform: `perspective(2000px) rotateX(${cursor.x}deg) rotateY(${cursor.y}deg)`
                            }}/>
                            <img className='wow fadeInUp p2' src={'/img/home/friends/p2.png'} style={{
                                transform: `perspective(2000px) rotateX(${cursor.x}deg) rotateY(${cursor.y}deg)`
                            }}/>
                            <img className='wow fadeInUp left' src={'/img/home/friends/left.png'} style={{
                                transform: `perspective(2000px) rotateX(${cursor.x}deg) rotateY(${cursor.y}deg)`
                            }}/>
                            <img className='wow fadeInUp right' src={'/img/home/friends/right.png'} style={{
                                transform: `perspective(2000px) rotateX(${cursor.x}deg) rotateY(${cursor.y}deg)`
                            }}/>
                            <div className='wow fadeInUp content-1 content'>
                                <Content1/>
                            </div>
                            <div className='wow fadeInUp content-2 content'>
                                <Content2/>
                            </div>
                        </>
                    }


                </ContentWrapper>
            </PageWrapper>
        </Section>
    )
};

export default Friends;