import styled from "styled-components";

export const Wrapper = styled.div`{
  text-align: center;
  overflow: hidden;
}`;

export const Section = styled.div<{ isFull: boolean, paddingTop?: number, paddingBottom?: number, backgroundColor?: string }>`{
  width: 100vw;
  ${p => (p.paddingTop && p.paddingTop > 0) && `
    padding-top: ${p.paddingTop}px;
  `}
  ${p => (p.paddingBottom && p.paddingBottom > 0) && `
    padding-bottom: ${p.paddingBottom}px;
  `}
  ${p => p.backgroundColor && `
    background: ${p.backgroundColor};
  `}
  ${p => p.isFull ? `
   height: 100vh; 
  ` : `
   margin-top: -10px;
  `}
}`

export const TitleBox = styled.div<{ isMobile?: boolean, margin?: number }>`{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;

  font-family: Soloist;
  font-style: normal;
  font-weight: 400;
  color: #062460;
  text-align: center;
  margin-top: 10px;

  > .title {
    ${p => p.isMobile ? `
    font-size: 34px;
    line-height: 150%;
    font-feature-settings: 'kern' off;
    ` : `    
    font-size: 60px;
    line-height: 90px;
    letter-spacing: 0em;    
    `}

    ${p => p.margin && `
      margin-bottom: ${p.margin}px;
    `}
  }

  > .title-background {
    ${p => p.isMobile ? `
    display: none;
    ` : `
    box-sizing: border-box;
    position: absolute;
    z-index: 1;
    top: -35px;
    left: 50%;
    transform: translate(-50%, 0);
    padding-right: 120px;
    width: 100%;
    max-width: 1440px;
    font-family: Soloist;
    font-size: 180px;
    font-weight: 400;
    line-height: 180px;
    letter-spacing: -0.005em;
    text-align: right;
    color: #062460;
    opacity: 0.05;    
    `}

  }

  > .sub-title-s-1 {
    
    ${p => p.isMobile ? `
    font-family: 'Pretendard';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    text-align: center;
    letter-spacing: -0.005em;
    color: #062460;
    opacity: 0.8;
    padding: 0 24px;
    word-break: keep-all;
    ` : `
    font-family: 'Pretendard';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    text-align: center;
    letter-spacing: -0.005em;
    color: #062460;
    `}
  }

  > .sub-title-s-2 {
    ${p => p.isMobile ? `
    padding: 0 24px;
    word-break: keep-all;
    font-family: 'Pretendard';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    text-align: center;
    letter-spacing: -0.005em;
    color: #062460;
    ` : `
    `}
  }

  > .sub-title-s {
    ${p => p.isMobile ? `
    word-break: keep-all;
    width: 100%;
    box-sizing: border-box;
    padding: 0 24px;
    font-family: Pretendard;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: -0.005em;
    text-align: center;
    ` : `
    font-family: Pretendard;
    font-size: 16px;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: -0.005em;
    text-align: center;
    color: #062460;
    `}
  }


  > .sub-title-m {
    font-family: Pretendard;

    letter-spacing: -0.005em;
    text-align: center;
    color: #062460;
    ${p => p.isMobile ? `
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
    max-width: 280px;
    ` : `
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    `}
  }

  > .margin-top-30 {
    margin-top: 30px;
  }
}`;

const GradientButtonWrapper = styled.div<{ marginTop: number }>`{
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  cursor: pointer;
  margin-top: ${p => p.marginTop}px;
  box-sizing: border-box;
  position: relative;
  border-radius: 83px;
  height: 64px;
  width: 340px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  @keyframes vertical_out {
    0% {
      opacity: 1;
      -webkit-transform: translateY(0);
      transform: translateY(0)
    }
    50% {
      opacity: 0;
      -webkit-transform: translateY(50%);
      transform: translateY(50%)
    }
    51% {
      opacity: 0;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%)
    }
    to {
      opacity: 1;
      -webkit-transform: translateY(0);
      transform: translateY(0)
    }
  }
  @keyframes vertical_in {
    0% {
      opacity: 1;
      -webkit-transform: translateY(0);
      transform: translateY(0)
    }
    50% {
      opacity: 0;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%)

    }
    51% {
      opacity: 0;
      -webkit-transform: translateY(50%);
      transform: translateY(50%)
    }
    to {
      opacity: 1;
      -webkit-transform: translateY(0);
      transform: translateY(0)
    }
  }


  > .bg {
    transition: all 1s ease-out;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
    width: 100%;
    height: 100%;
    background: linear-gradient(90.07deg, #FFFFFF 7.1%, #BCDFF3 43.32%, #E09EEA 57.7%, #FFFFFF 92.68%);
    border-radius: 83px;
    opacity: 1;

  }

  > .bg-hover {
    transition: all 1s ease-out;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
    width: 100%;
    height: 100%;
    background: linear-gradient(92.1deg, #BCDFF3 16.15%, #E09EEA 79.17%);
    border-radius: 83px;
    opacity: 0;
  }

  > span {
    position: relative;
    width: 100%;
    font-family: Pretendard;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.005em;
    text-align: center;
    color: #062460;
    z-index: 1;
  }

  &:hover {
    > .bg {
      opacity: 0;
    }

    > .bg-hover {
      opacity: 1;
    }

    > span {
      animation: vertical_out;
      animation-duration: .3s;
    }
  }

  &:not(hover) {
    > span {
      animation: vertical_in;
      animation-duration: .3s;
    }
  }
}`;

export const GradientButton = ({
                                   children,
                                   className,
                                   marginTop
                               }: { children: any, className: string, marginTop: number }) => {
    return <GradientButtonWrapper marginTop={marginTop} className={className}>
        {children}
        <div className='bg'/>
        <div className='bg-hover'/>
    </GradientButtonWrapper>
}

export const StickerWrapper = styled.div<{ height: number }>`{
  position: absolute;
  width: 100vw;
  height: ${p => p.height}px;
  left: 50%;
  transform: translate(-50%, 0);
}`;

export const Sticker = styled.img<{ width: number, height: number, top?: number, left?: number, right?: number }>`{
  position: absolute;
  width: ${p => p.width}px;
  height: ${p => p.height}px;
  ${p => p.top && `
    top: ${p.top}px;
  `}
  ${p => p.left && `
    left: calc(max(50vw, 720px) - 720px + ${p.left}px);
  `}
  ${p => p.right && `
    right: calc(max(50vw, 720px) - 720px + ${p.right}px);
  `}

}`;