import {GradientButton, Section, TitleBox, Wrapper} from "../../components/Common";
import styled from "styled-components";

const PageWrapper = styled.div`{
  position: relative;
  width: 100vw;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}`;

const CardWrapper = styled.div<{ isMobile: boolean }>`{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  ${p => p.isMobile ? `
  margin-top: 30px;
  display: grid;
  overflow: hidden;
  padding: 0 24px;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: auto;
  grid-column-gap: 20px;
  grid-row-gap: 30px;
  justify-items: center;
  max-width: 100%;
  ` : `
  margin-top: 80px;
  `}
}`;

const Card = styled.div<{ isMobile: boolean }>`{
  display: flex;
  flex-direction: column;
  margin: 0 10px;

  ${p => p.isMobile ? `
    width: 100%;
    max-width: 200px;
  ` : `
  `}
  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  > .profile-img {
    ${p => p.isMobile ? `
      width: 100%;
    ` : `
    
    `}
  }

  > .title {
    margin-top: 30px;
    font-family: Soloist;

    font-weight: 400;
    line-height: 30px;
    letter-spacing: -0.005em;
    text-align: center;
    color: #062460;
    ${p => p.isMobile ? `
    font-size: 16px;
    ` : `
    font-size: 20px;
    `}
  }

  > .description {
    margin-top: 8px;
    font-family: Pretendard;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.005em;
    text-align: center;
    color: #062460;
    ${p => p.isMobile ? `
    font-size: 14px;
    ` : `
    font-size: 16px;
    `}
  }
}`;

const Team = (props: any) => {
    const isMobileVal = props.isMobileVal;
    return (
        <Section ref={props.innerRef} isFull={false} paddingTop={120} paddingBottom={isMobileVal ? 60 : 140}>
            <PageWrapper>
                <TitleBox className='wow fadeInUp' margin={18} isMobile={isMobileVal}>
                    <div className='title'>
                        Team
                    </div>
                    <div className='title-background'>
                        Team
                    </div>
                </TitleBox>
                <CardWrapper isMobile={isMobileVal}>
                    <Card isMobile={isMobileVal} className='wow fadeInUp'>
                        <img className='profile-img' src='/img/home/team/dal.png'/>
                        <span className='title'>DAL</span>
                        <span className='description'>CEO</span>
                    </Card>
                    <Card isMobile={isMobileVal} className='wow fadeInUp'>
                        <img className='profile-img' src='/img/home/team/davin.png'/>
                        <span className='title'>DAVIN</span>
                        <span className='description'>Photographer</span>
                    </Card>
                    <Card isMobile={isMobileVal} className='wow fadeInUp'>
                        <img className='profile-img' src='/img/home/team/black_manta.png'/>
                        <span className='title'>Black_Manta</span>
                        <span className='description'>Project Management</span>
                    </Card>
                    <Card isMobile={isMobileVal} className='wow fadeInUp'>
                        <img className='profile-img' src='/img/home/team/talier.png'/>
                        <span className='title'>TAILER</span>
                        <span className='description'>Developer</span>
                    </Card>
                </CardWrapper>
            </PageWrapper>
        </Section>
    )
};

export default Team;