const theme = {
    color: {
        white: '#FFFFFF',
    },
    typo: {
        head: {
            h14b: ``,
        },
        para: {
            p18: ``,
        },
        label: {
            l18: ``,
        }
    }
};

export default theme;
